import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { IntroHeaderConfig } from './intro-header-config';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-site-intro-header',
  templateUrl: './site-intro-header.component.html',
  styleUrls: ['./site-intro-header.component.scss']
})

export class SiteIntroHeaderComponent implements OnInit {
  @Input() config: IntroHeaderConfig;
  @ViewChild('introBox') introBox: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  language: string;
  menuSticky: boolean = false;
  private langSubscription: Subscription;

  constructor(
    private translate: TranslateService,
  ) { }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    if (this.config.displayMenu) {
      this.stickyMenuHandler();
    }
  } 

  ngOnInit() {
    this.getLanguage();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

  private getMenuOffset() {
    const offset = this.introBox.nativeElement.clientHeight - this.menu.nativeElement.clientHeight + 15; // don't forget the top padding 1rem
    return offset;
  }

  private stickyMenuHandler() {
    if (this.getMenuOffset() - window.pageYOffset <= 0) {
      this.menuSticky = true;
    } else {
      this.menuSticky = false;
    }
  }
}

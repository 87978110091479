import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '../../../node_modules/@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit, OnDestroy {
  language: string;
  private langSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

  public switchLanguage(langKey) {
    // get current path
    let path = this.route.routeConfig.path;
    path = path.replace(':langParam/', '');

    if (path.indexOf('langParam') > -1) { // for the frontpage
      this.router.navigate([langKey]);
    } else {
      this.router.navigate([langKey, path]);
    }
  }
}

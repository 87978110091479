import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profileKey: string;
  @Input() profilesData: any = {};

  language: string;
  private langSubscription: Subscription;

  hoverState: boolean = false;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    // console.log(this.profileKey, this.profilesData);
    this.getLanguage();
  }

  public setProfileHover(state: boolean) {
    this.hoverState = state;
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

}

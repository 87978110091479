import { Component, OnInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-frontpage",
  templateUrl: "./frontpage.component.html",
  styleUrls: ["./frontpage.component.scss"]
})
export class FrontpageComponent implements OnInit, OnDestroy {
  private sub: any;

  constructor(private title: Title, private translate: TranslateService) {}

  ngOnInit() {
    this.setPageTitle();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get("frontpage.pageTitle").subscribe(res => {
      this.title.setTitle(res);
    });
  }

  public scrollDown() {
    
    
    this.scrollTo(window.innerHeight * .25, 300);
  }

  /*
   * Utils
   */

  private scrollTo = function(to, duration) {
    const element = document.scrollingElement || document.documentElement,
      start = element.scrollTop,
      change = to - start,
      startDate = +new Date(),
      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      },
      animateScroll = function() {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, duration)
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = to;
        }
      };
    if (change > 0) {
      animateScroll();
    }
  };
}

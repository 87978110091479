import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  param: any;

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
     // this language will be used as a fallback when a translation isn't found in the current language
     translate.setDefaultLang('de');

     // the lang to use, if the lang isn't available, it will use the current loader to get them
     translate.use('de');
  }

  ngOnInit() {
    this.setLanguage();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
      document.querySelector('body').classList.remove('no-scroll');
    });
  }

  private setLanguage() {
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.param = val.state.root.firstChild.params.langParam;
        if (this.param !== 'de' && this.param !== 'en') {
          this.router.navigate(['/']);
        } else {
          this.translate.use(this.param);
        }
      }
    });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../site-intro-header/intro-header-config';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-kanzlei',
  templateUrl: './kanzlei.component.html',
  styleUrls: ['./kanzlei.component.scss']
})
export class KanzleiComponent implements OnInit, OnDestroy {
  introHeader: IntroHeaderConfig;
  private sub: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.meta.updateTag({ name: 'description', content: 'Seit 1950 beraten wir Unternehmen, Vorstände, Geschäftsführer, Verbände, Stiftungen und Privatpersonen und kümmern uns gewissenhaft um jedes Anliegen.'});
    this.meta.updateTag({ property: 'og:description', content: 'Seit 1950 beraten wir Unternehmen, Vorstände, Geschäftsführer, Verbände, Stiftungen und Privatpersonen und kümmern uns gewissenhaft um jedes Anliegen.'});

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: true,
      title: 'kanzlei.title',
      subtitle: 'kanzlei.subtitle'
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get('kanzlei.pageTitle').subscribe(res => {
      this.title.setTitle(res);
    });
  }

}

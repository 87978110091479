import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-site-mobile-menu',
  templateUrl: './site-mobile-menu.component.html',
  styleUrls: ['./site-mobile-menu.component.scss']
})
export class SiteMobileMenuComponent implements OnInit {
  menuOpen: boolean = false;
  language: string;
  private langSubscription: Subscription;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;

    if (this.menuOpen) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../../site-intro-header/intro-header-config';
import { ActivatedRoute, Router } from '@angular/router';
import { schwerpunkte } from './schwerpunkte.content';
import * as profilesContent from '../../rechtsanwaelte/profile-page/profiles.content';
import _ from 'lodash';
import { validateConfig } from '../../../../node_modules/@angular/router/src/config';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-schwerpunkte-singlepage',
  templateUrl: './schwerpunkte-singlepage.component.html',
  styleUrls: ['./schwerpunkte-singlepage.component.scss']
})
export class SchwerpunkteSinglepageComponent implements OnInit, OnDestroy {
  introHeader: IntroHeaderConfig;
  schwerpunktKey: any;
  pageContent: any = {
    "title": null,
    "teaser": null,
    "text": null,
    "ansprechpartner": []
  };
  private sub: any;
  profilesKeys: any[] = [];
  profilesData = profilesContent.profiles;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getSchwerpunktKey();

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: false,
      title: 'schwerpunkte.fachgebieteSinglePage.title',
      subtitle: 'schwerpunkte.fachgebieteSinglePage.subtitle'
    }

    this.setPageContent();

    this.translate.get(`schwerpunkte.fachgebieteSinglePage.${this.schwerpunktKey}.pageTitle`).subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res });
    });
    this.translate.get(`schwerpunkte.fachgebieteSinglePage.${this.schwerpunktKey}.metaDescription`).subscribe(res => {
      this.meta.updateTag({ name: 'description', content: res });
      this.meta.updateTag({ property: 'og:description', content: res });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getSchwerpunktKey() {
    this.sub = this.route.params.subscribe(params => {
      this.schwerpunktKey = params['schwerpunktKey'];
   });
  }

  private setPageContent() {
    if (!schwerpunkte.hasOwnProperty(this.schwerpunktKey)) {
      this.router.navigate(['/']);
    } else {
      this.pageContent = {
        title: `schwerpunkte.fachgebieteSinglePage.${this.schwerpunktKey}.title`,
        teaser: `schwerpunkte.fachgebieteSinglePage.${this.schwerpunktKey}.teaser`,
        text: `schwerpunkte.fachgebieteSinglePage.${this.schwerpunktKey}.text`,
        ansprechpartner: this.selectProfilesFromSchwerpunkte(this.schwerpunktKey),
      };
    }
  }

  private selectProfilesFromSchwerpunkte(schwerpunktKey: string) {
    let profileKeys = [];
    _.map(this.profilesData, (val, key) => {
      if (_.findIndex(val.skills, (obj) => {
        return schwerpunktKey.indexOf(obj.key) > -1;
      }) > -1) {
        profileKeys.push(key);
      }
    });

    return profileKeys;
  }
}

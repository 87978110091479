import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../site-intro-header/intro-header-config';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-schwerpunkte',
  templateUrl: './schwerpunkte.component.html',
  styleUrls: ['./schwerpunkte.component.scss']
})
export class SchwerpunkteComponent implements OnInit, OnDestroy {
  introHeader: IntroHeaderConfig;
  private sub: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.meta.updateTag({ name: 'description', content: 'Wir beraten. Wir vertreten. Wir coachen. Wir unterstützen Sie bei Ihren Anliegen und finden maßgeschneiderte Lösungen.' });
    this.meta.updateTag({ property: 'og:description', content: 'Wir beraten. Wir vertreten. Wir coachen. Wir unterstützen Sie bei Ihren Anliegen und finden maßgeschneiderte Lösungen.' });

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: true,
      title: 'schwerpunkte.title',
      subtitle: 'schwerpunkte.subtitle'
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get('schwerpunkte.pageTitle').subscribe(res => {
      this.title.setTitle(res);
    });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontpageComponent } from './frontpage/frontpage.component';
import { KanzleiComponent } from './kanzlei/kanzlei.component';
import { RechtsanwaelteComponent } from './rechtsanwaelte/rechtsanwaelte.component';
import { SchwerpunkteComponent } from './schwerpunkte/schwerpunkte.component';
import { SchwerpunkteSinglepageComponent } from './schwerpunkte/schwerpunkte-singlepage/schwerpunkte-singlepage.component';
import { ProfilePageComponent } from './rechtsanwaelte/profile-page/profile-page.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { KarriereComponent } from './karriere/karriere.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'de',
    pathMatch: 'full'
  },
  {
    path: ':langParam',
    component: FrontpageComponent
  },
  {
    path: ':langParam/kanzlei',
    component: KanzleiComponent
  },
  {
    path: ':langParam/rechtsanwaelte',
    component: RechtsanwaelteComponent
  },
  {
    path: ':langParam/rechtsanwaelte/:profileKey',
    component: ProfilePageComponent
  },
  {
    path: ':langParam/schwerpunkte',
    component: SchwerpunkteComponent,
  },
  {
    path: ':langParam/schwerpunkte/:schwerpunktKey',
    component: SchwerpunkteSinglepageComponent
  },
  {
    path: ':langParam/karriere',
    component: KarriereComponent
  },
  {
    path: ':langParam/impressum',
    component: ImpressumComponent
  },
  {
    path: ':langParam/datenschutz',
    component: DatenschutzComponent
  },
  {
    path: '**',
    redirectTo: 'de',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontpageComponent } from './frontpage/frontpage.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { KanzleiComponent } from './kanzlei/kanzlei.component';
import { SiteIntroHeaderComponent } from './site-intro-header/site-intro-header.component';
import { RechtsanwaelteComponent } from './rechtsanwaelte/rechtsanwaelte.component';
import { SchwerpunkteComponent } from './schwerpunkte/schwerpunkte.component';
import { SchwerpunkteSinglepageComponent } from './schwerpunkte/schwerpunkte-singlepage/schwerpunkte-singlepage.component';
import { ProfilePageComponent } from './rechtsanwaelte/profile-page/profile-page.component';
import { ProfileComponent } from './rechtsanwaelte/profile/profile.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { SiteMobileMenuComponent } from './site-mobile-menu/site-mobile-menu.component';

import { PreviousRouteService } from './services/previous-route.service';
import { KarriereComponent } from './karriere/karriere.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FrontpageComponent,
    SiteFooterComponent,
    KanzleiComponent,
    SiteIntroHeaderComponent,
    RechtsanwaelteComponent,
    SchwerpunkteComponent,
    SchwerpunkteSinglepageComponent,
    ProfilePageComponent,
    ProfileComponent,
    ImpressumComponent,
    DatenschutzComponent,
    SiteMobileMenuComponent,
    KarriereComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    HttpClient,
    PreviousRouteService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

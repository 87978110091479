export const profiles = {
  ralphLandsittel: {
    name: "Prof. Dr.<br>Ralph Landsittel",
    image: "/assets/images/profiles/landsittel.jpg",
    vcard: "/assets/download/prof-dr-ralph-landsittel.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "landsittel@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english"
    ],
    skills: [
      {
        key: "erbrechtUnternehmensnachfolge",
        name: "rechtsanwaelte.skills.erbrechtUnternehmensnachfolge",
        link: ""
      },
      {
        key: "steuerrecht",
        name: "rechtsanwaelte.skills.steuerrecht",
        link: ""
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.ralphLandsittel.vita.teaser",
      text: "rechtsanwaelte.ralphLandsittel.vita.text"
    },
    publicationsCols: true,
    publications: "rechtsanwaelte.ralphLandsittel.publications"
  },
  andreasPentz: {
    name: "Prof. Dr.<br>Andreas Pentz",
    image: "/assets/images/profiles/pentz.jpg",
    vcard: "/assets/download/prof-dr-andreas-pentz.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "pentz@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english"
    ],
    skills: [
      {
        key: "corporateMA",
        name: "rechtsanwaelte.skills.corporateMA"
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.andreasPentz.vita.teaser",
      text: "rechtsanwaelte.andreasPentz.vita.text"
    },
    publicationsCols: true,
    publications: "rechtsanwaelte.andreasPentz.publications"
  },
  andreHaug: {
    name: "André Haug",
    image: "/assets/images/profiles/haug.jpg",
    vcard: "/assets/download/andre-haug.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "haug@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english",
      "rechtsanwaelte.profiles.languagesList.french"
    ],
    skills: [
      {
        key: "gewRechtsschutz",
        name: "rechtsanwaelte.skills.gewRechtsschutz",
        link: ""
      },
      {
        key: "vergaberecht",
        name: "rechtsanwaelte.skills.vergaberecht",
        link: ""
      },
      {
        key: "wettbewerbsrecht"
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.andreHaug.vita.teaser",
      text: "rechtsanwaelte.andreHaug.vita.text"
    },
    publicationsCols: false,
    publications: "rechtsanwaelte.andreHaug.publications"
  },
  ingridStahl: {
    name: "Ingrid Stahl",
    image: "/assets/images/profiles/stahl.jpg",
    vcard: "/assets/download/ingrid-stahl.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "stahl@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english"
    ],
    skills: [
      {
        key: "gewRechtsschutz",
        name: "rechtsanwaelte.skills.gewRechtsschutz",
        link: ""
      },
      {
        key: "wettbewerbsrecht"
      },
      {
        key: "arbeitsrecht",
        name: "rechtsanwaelte.skills.arbeitsrecht",
        link: ""
      },
      {
        key: "erbrecht",
        name: "rechtsanwaelte.skills.erbrecht",
        link: ""
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.ingridStahl.vita.teaser",
      text: "rechtsanwaelte.ingridStahl.vita.text"
    },
    publicationsCols: false,
    publications: "rechtsanwaelte.ingridStahl.publications"
  },
  alexanderBelz: {
    name: "Dr. Alexander Belz",
    image: "/assets/images/profiles/belz.jpg",
    vcard: "/assets/download/dr-alexander-belz.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "belz@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english"
    ],
    skills: [
      {
        key: "corporateMA",
        name: "rechtsanwaelte.skills.corporateMA"
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.alexanderBelz.vita.teaser",
      text: "rechtsanwaelte.alexanderBelz.vita.text"
    },
    publications: "rechtsanwaelte.alexanderBelz.publications"
  },
  lisaSchmidt: {
    name: "Lisa Schmidt, LL.M.",
    image: "/assets/images/profiles/Schmidt.png",
    vcard: "/assets/download/lisa-schmidt.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "schmidt@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english"
    ],
    skills: [
      {
        key: "corporateMA",
        name: "rechtsanwaelte.skills.corporateMA"
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.lisaSchmidt.vita.teaser",
      text: "rechtsanwaelte.lisaSchmidt.vita.text"
    }
  },
  koletaEjlli: {
    name: "Koleta Ejlli, LL.M.",
    image: "/assets/images/profiles/ejlli.jpg",
    vcard: "/assets/download/koleta-ejlli.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "ejlli@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english",
      "rechtsanwaelte.profiles.languagesList.albanian",
      "rechtsanwaelte.profiles.languagesList.french"
    ],
    skills: [
      {
        key: "erbrechtUnternehmensnachfolge",
        name: "rechtsanwaelte.skills.erbrechtUnternehmensnachfolge",
        link: ""
      },
      {
        key: "steuerrecht",
        name: "rechtsanwaelte.skills.steuerrecht",
        link: ""
      }

    ],
    vita: {
      teaser: "rechtsanwaelte.koletaEjlli.vita.teaser",
      text: "rechtsanwaelte.koletaEjlli.vita.text"
    },
    publicationsCols: true,
    publications: "rechtsanwaelte.koletaEjlli.publications"
  },
  anneBueckert: {
    name: "Anne Bückert",
    image: "/assets/images/profiles/bueckert.jpg",
    vcard: "/assets/download/anne-bueckert.vcf",
    address: "Augustaanlage 59 · 68165 Mannheim",
    phone: "+49 621 41938-0",
    fax: "+49 621 41938-80",
    email: "bueckert@rowedder.de",
    languages: [
      "rechtsanwaelte.profiles.languagesList.german",
      "rechtsanwaelte.profiles.languagesList.english"
    ],
    skills: [
      {
        key: "corporateMA",
        name: "rechtsanwaelte.skills.corporateMA"
      }
    ],
    vita: {
      teaser: "rechtsanwaelte.anneBückert.vita.teaser",
      text: "rechtsanwaelte.anneBückert.vita.text"
    }
  }
};
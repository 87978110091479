import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../site-intro-header/intro-header-config';
import * as profilesContent from './profile-page/profiles.content';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rechtsanwaelte',
  templateUrl: './rechtsanwaelte.component.html',
  styleUrls: ['./rechtsanwaelte.component.scss']
})
export class RechtsanwaelteComponent implements OnInit, OnDestroy {
  introHeader: IntroHeaderConfig;
  profiles: any[] = [];
  profilesData = profilesContent.profiles;
  profilesKeys = Object.keys(profilesContent.profiles);
  private sub: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.meta.updateTag({name: 'description', content: 'Wir verbinden wissenschaftliche Expertise mit der Freude an der Vermittlung praktischer Lösungen und arbeiten mit hoher Leidenschaft für unsere Tätigkeit.'});
    this.meta.updateTag({property: 'og:description', content: 'Wir verbinden wissenschaftliche Expertise mit der Freude an der Vermittlung praktischer Lösungen und arbeiten mit hoher Leidenschaft für unsere Tätigkeit.'});

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: true,
      title: 'rechtsanwaelte.title',
      subtitle: 'rechtsanwaelte.subtitle'
    };
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get('rechtsanwaelte.pageTitle').subscribe(res => {
      this.title.setTitle(res);
    });
  }
}

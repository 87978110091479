import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../site-intro-header/intro-header-config';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit, OnDestroy {
  introHeader: IntroHeaderConfig;
  private sub: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.meta.updateTag({name: 'description', content: 'Verantwortlich: ROWEDDER ZIMMERMANN HASS Rechtsanwälte PartmbB • Augustaanlage 59 • D-68165 Mannheim • Telefon: +49 621 41938 - 0 • E- Mail: mail@rowedder.de' });
    this.meta.updateTag({property: 'og:description', content: 'Verantwortlich: ROWEDDER ZIMMERMANN HASS Rechtsanwälte PartmbB • Augustaanlage 59 • D-68165 Mannheim • Telefon: +49 621 41938 - 0 • E- Mail: mail@rowedder.de' });

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: true,
      title: 'legalNotice.title'
    };
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get('legalNotice.pageTitle').subscribe(res => {
      this.title.setTitle(res);
    });
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../site-intro-header/intro-header-config';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-karriere',
  templateUrl: './karriere.component.html',
  styleUrls: ['./karriere.component.scss']
})
export class KarriereComponent implements OnInit {
  introHeader: IntroHeaderConfig;
  private sub: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.meta.updateTag({ name: 'description', content: 'Sie wollen Teil unserer Kanzlei werden und in einem spannenden Umfeld arbeiten? Dann bewerben Sie sich und überzeugen uns von Ihrer Qualifikation.'});
    this.meta.updateTag({ property: 'og:description', content: 'Sie wollen Teil unserer Kanzlei werden und in einem spannenden Umfeld arbeiten? Dann bewerben Sie sich und überzeugen uns von Ihrer Qualifikation.'});

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: true,
      title: 'karriere.title',
      subtitle: 'karriere.subtitle'
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get('karriere.pageTitle').subscribe(res => {
      this.title.setTitle(res);
    });
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { PreviousRouteService } from '../../services/previous-route.service';
import { Router, ActivatedRoute } from '@angular/router';
import { profiles } from './profiles.content';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  activeTab: string = 'vita'; // 'vita' | 'publications'
  previousUrl: any;
  profileKey: any;
  profile: any = {};
  private sub: any;
  language: string;
  private langSubscription: Subscription;

  constructor(
    private location: Location,
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit() {
    this.getProfileKey();
    this.setPageContent();
    this.getLanguage();

    this.translate.get(`rechtsanwaelte.${this.profileKey}.pageTitle`).subscribe(res => {
      this.title.setTitle(res);
      this.meta.updateTag({ property: 'og:title', content: res });
    });
    this.translate.get(`rechtsanwaelte.${this.profileKey}.metaDescription`).subscribe(res => {
      this.meta.updateTag({ name: 'description', content: res });
      this.meta.updateTag({ property: 'og:description', content: res });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.langSubscription.unsubscribe();
  }

  private getLanguage() {
    this.language = this.translate.currentLang;
    this.langSubscription = this.translate.onLangChange.subscribe(res => {
      this.language = res.lang;
    });
  }

  public locationBack() {
    let prevUrl = this.previousRouteService.getPreviousUrl().replace('/' + this.previousRouteService.getPreviousUrl().split('/')[1], '');
    let currentUrl = this.previousRouteService.getCurrentUrl().replace('/' + this.previousRouteService.getCurrentUrl().split('/')[1], '');

    if (prevUrl !== currentUrl) {
      this.location.back();
    } else {
      this.router.navigate(['../../rechtsanwaelte'], {relativeTo: this.route});
    }
  }

  private getProfileKey() {
    this.sub = this.route.params.subscribe(params => {
      this.profileKey = params['profileKey'];
   });
  }

  private setPageContent() {
    if (!profiles.hasOwnProperty(this.profileKey)) {
      this.router.navigate(['../../rechtsanwaelte'], {relativeTo: this.route});
    } else {
      this.profile = profiles[this.profileKey];
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroHeaderConfig } from '../site-intro-header/intro-header-config';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent implements OnInit, OnDestroy {
  introHeader: IntroHeaderConfig;
  private sub: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setPageTitle();
    this.meta.updateTag({name: 'description', content: 'Die betriebliche Datenschutzbeauftragte ist Frau Rechtsanwältin Ingrid Stahl. Bei Fragen zu unserem Datenschutz, steht Ihnen Frau Stahl gerne zur Verfügung.' });
    this.meta.updateTag({property: 'og:description', content: 'Die betriebliche Datenschutzbeauftragte ist Frau Rechtsanwältin Ingrid Stahl. Bei Fragen zu unserem Datenschutz, steht Ihnen Frau Stahl gerne zur Verfügung.' });

    // set the config and the properties in the i18n json
    this.introHeader = {
      displayMenu: true,
      title: 'privacy.title'
    };
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setPageTitle() {
    this.sub = this.translate.get('privacy.pageTitle').subscribe(res => {
      this.title.setTitle(res);
    });
  }

}

export const schwerpunkte = {
  erbrechtUnternehmensnachfolge: {
    title: "fachgebieteSinglePage.erbrecht.title",
    teaser: "fachgebieteSinglePage.erbrecht.teaser",
    text: "fachgebieteSinglePage.erbrecht.text"
  },
  steuerrecht: {
    title: "fachgebieteSinglePage.steuerrecht.title",
    teaser: "fachgebieteSinglePage.steuerrecht.teaser",
    text: "fachgebieteSinglePage.steuerrecht.text",
  },
  arbeitsrecht: {
    title: "fachgebieteSinglePage.arbeitsrecht.title",
    teaser: "fachgebieteSinglePage.arbeitsrecht.teaser",
    text: "fachgebieteSinglePage.arbeitsrecht.text",
  },
  wettbewerbsrecht: {
    title: "fachgebieteSinglePage.wettbewerbsrecht.title",
    teaser: "fachgebieteSinglePage.wettbewerbsrecht.teaser",
    text: "fachgebieteSinglePage.wettbewerbsrecht.text",
  },
  corporateMA: {
    title: "fachgebieteSinglePage.corporateMA.title",
    teaser: "fachgebieteSinglePage.corporateMA.teaser",
    text: "fachgebieteSinglePage.corporateMA.text",
  },
  prozesse: {
    title: "fachgebieteSinglePage.prozesse.title",
    teaser: "fachgebieteSinglePage.prozesse.teaser",
    text: "fachgebieteSinglePage.prozesse.text",
  },
  vergaberecht: {
    title: "fachgebieteSinglePage.vergaberecht.title",
    teaser: "fachgebieteSinglePage.vergaberecht.teaser",
    text: "fachgebieteSinglePage.vergaberecht.text",
  },
};